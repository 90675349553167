import React from 'react';
import Content from 'components/_ui/Content';
import { graphql } from 'gatsby';
import Seo from 'components/Seo';
import styled from '@emotion/styled'
import { Inner } from 'styles/structure';
import dimensions from 'styles/dimensions';
import colors from 'styles/colors';
import Layout from 'components/Layout';
import SubHero from 'components/SubHero';
import ContactForm from 'components/ContactForm';
import { withPreview } from 'gatsby-source-prismic';

const FormContainer = styled.div`
  margin-bottom: 6.4rem;
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    margin-bottom: 8rem;  
  }
`;

const FormWrapper = styled.div`
  flex: 1 1 100%;
`;

const ContactDetails = styled.div`
  margin-top: 6.4rem;
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-top: 0;
    margin-left: 8rem;
    width: 36.8rem;
    flex: 0 0 36.8rem;
  }
`;

const DetailBlock = styled.div`
  & + * {
    margin-top: 2.4rem;
  }
  
  a {
    display: inline;
    position: relative;
    color: ${colors.grey900};
    font-size: 1.8rem;
    background-image: linear-gradient(${colors.yellow500}, ${colors.yellow500});
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 100% 0.2rem;
    padding-bottom: 0.2rem;
    transition: color 0.08s ease-in-out;
    
    &:hover {
      color: ${colors.orange500};
    }
  }
`;

const ContactUs = ({ data }) => {
  const contactPage = data.allPrismicContactPage.edges[0].node.data;

  return (
    <Layout>
      <Seo
        title={contactPage.social_title || 'Contact Us'}
        description={contactPage.social_description}
        image={contactPage.social_image}
      />

      {(contactPage.form_title || contactPage.form_description) && (
        <SubHero
          heading={contactPage.form_title}
          content={contactPage.form_description}
        />
      )}

      <Inner>
        <FormContainer>
          <FormWrapper>
            <ContactForm contactPage={contactPage} />
          </FormWrapper>

          <ContactDetails>
            {contactPage.address && (
              <DetailBlock>
                <h5>Address</h5>
                <Content content={contactPage.address.raw} />
              </DetailBlock>
            )}

            {contactPage.email && (
              <DetailBlock>
                <h5>Email Address</h5>
                <a href={`mailto:${contactPage.email}`}>{contactPage.email}</a>
              </DetailBlock>
            )}
          </ContactDetails>
        </FormContainer>
      </Inner>
    </Layout>
  );
};

export const query = graphql`
  {
    allPrismicContactPage {
      edges {
        node {
          uid
          data {
            form_description {
              raw
            }
            email
            address {
              raw
              text
            }
            form_title {
              raw
            }
            careerhub_checkbox_description {
              raw
            }
            careerhub_checkbox_title {
              raw
            }
            newsletter_checkbox_description {
              text
              raw
            }
            newsletter_checkbox_title {
              text
              raw
            }
            gender_checkbox_title {
              text
              raw
            }
            gender_checkbox_description {
              text
              raw
            }
            board_leadership_checkbox_title {
              text
              raw
            }
            board_leadership_checkbox_description {
              text
              raw
            }
            sign_me_up_text {
              text
              raw
            }
            social_title
            social_description
            social_image {
              url
            }
          }
        }
      }
    }
  }
`;

export default withPreview(ContactUs);