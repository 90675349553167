import React from 'react';
import styled from '@emotion/styled'
import colors from 'styles/colors';
import { Link } from "gatsby";

const BreadcrumbsContainer = styled.div`
  & + * {
    margin-top: 1.6rem;
  }
  
  a {
    display: inline-block;
    color: ${colors.yellow500};
    font-size: 1.6rem;
    font-weight: 500;
    transition: color 0.12s ease-in-out;
    
    &::after {
      content: '/';
      display: inline-block;
      margin: 0 0.8rem;
      color: ${colors.grey500}; 
    }
    
    &:hover {
      color: ${colors.teal600};
    }
  }
`;

const Breadcrumbs = (props) => {
  return (
    <BreadcrumbsContainer>
      {props.breadcrumbs.map((crumb, i) => (
        <Link key={i} to={crumb.destination}>{crumb.text}</Link>
      ))}
    </BreadcrumbsContainer>
  );
};

export default Breadcrumbs;
