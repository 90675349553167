import React from 'react';
import RichText from 'components/RichText';
import Content from 'components/_ui/Content';
import styled from '@emotion/styled'
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import { Inner } from 'styles/structure';
import Breadcrumbs from 'components/Breadcrumbs';
import ArrowLink from 'components/_ui/ArrowLink';

const HeroContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 2.4rem;
  
  & + * {
    margin-top: 5.6rem;
  }

  &.SubHero--faqs {
    background-color: ${colors.grey100};
    padding: 4rem 0;
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    & + * {
      margin-top: 8.8rem;
    }
    
    &.SubHero--faqs {
      padding-top: 7.2rem;
    }
  }
`;

const HeroInner = styled(Inner)`
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    align-items: center;
  }
`;

const HeroContent = styled.div`
  /* minor display correction for clipped headings without content */
  padding-bottom: 0.4rem;
  
  header {
    & + * {
      margin-top: 2.4rem;
    }
  }
  
  p {
    max-width: 52rem;
  }
`;

const HeroBlock = styled.div`
  & + * {
    margin-top: 1.6rem;
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    flex: 1 1 100%;
    
    & + * {
      margin-top: 0;
      flex: 0 0 auto;
    }
  }
`;

const SubHero = ({ breadcrumbs, heading, content, variant, buttonText, destination }) => {
  let heroHeading = '';

  if (heading && heading.raw) {
    heroHeading = <RichText render={heading.raw}/>;
  } else if (heading) {
    heroHeading = <h1>{heading}</h1>;
  }

  return (
    <HeroContainer className={`${variant ? `SubHero--${variant}` : ''}`}>
      <HeroInner>
        <HeroBlock>
          {breadcrumbs && breadcrumbs.length > 0 && (
            <Breadcrumbs breadcrumbs={breadcrumbs}/>
          )}

          <HeroContent>
            {heading && (
              <header>
                {heroHeading}
              </header>
            )}

            {content && <Content content={content.raw}/>}
          </HeroContent>
        </HeroBlock>

        {(buttonText && destination) && (
          <HeroBlock>
            <ArrowLink destination={destination} text={buttonText}/>
          </HeroBlock>
        )}
      </HeroInner>
    </HeroContainer>
  );
};

export default SubHero;
