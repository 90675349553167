/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component } from 'react';
import styled from '@emotion/styled'
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import Checkbox from 'components/_ui/Checkbox';
import Button from 'components/_ui/Button';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const FormButton = styled(Button)`
  background: #fff;
  
  @media (min-width: ${dimensions.desktopUp}px) {
    width: 80%;
  }
`;

const Form = styled.form`
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: auto;
  grid-gap: 2rem;
  align-items: center;
  padding: 6.9rem 2rem 8.2rem 2rem;
  margin: 0;
  height: 100%;
  flex-direction: column;
  background-color: ${colors.grey200};
  max-width: 800px;

  &.is-submitted {
    button {
      display: none;
    }
  }
  
  & > * {
    font-size: 1.6rem;
  }
  
  input {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 6.9rem 11.2rem 8.2rem 7rem;
    grid-gap: 3rem;

    .message {
      grid-column: 1 /3;
      grid-row: 4;

      text-area {
        height: 164rem;
      }
    }
    .email {
      grid-row: 2;
      grid-column: 1 / 3;
    }

    label {
      font-size: 1.6rem;
    }
  }
`;

const CheckBoxContainer = styled.div`
  display: grid;
  grid-template-rows: 15rem, 15rem;
  grid-template-columns: 5rem, 10rem;

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    grid-column: 1/3;
  }

  label {
    grid-row: 1;
    grid-column: 1;
    padding-right: 2rem;
  }

  h6 {
    color: ${colors.teal600};
    padding-bottom: 0.8rem;
    line-height: 1;
  }

  p {
    grid-row: 2 / 3;
    grid-column: 2;
  }

  & + * {
    color: ${colors.teal600};
  }
`;

const EmailInput = styled.input`
  margin-bottom: 1rem;
  height: 6rem;
  border: 1px solid #eee;
  width: 100%;
  padding: 0 1.6rem;

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    grid-column: 1/3;
  }

  & + * {
    color: ${colors.teal600};
  }
`;

const TextArea = styled.textarea`
  border: 1px solid #eee;
  resize: none;
  height: 10rem;
  width: 100%;
  padding: 1.6rem;

  input {
    border: solid;
  }

  & + * {
    color: ${colors.teal600};
  }
`;

const Input = styled.input`
  height: 6rem;
  border: 1px solid #eee;
  width: 100%;

  & + * {
    color: ${colors.teal600};
  }
`;

const SignMeUp = styled.div`
  color: ${colors.teal600};
  font-size: 1.6rem;
`;

const StatusMessage = styled.div`
  color: ${colors.orange300};
  font-size: 2rem;
  font-weight: 500;
`;

export default class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      organization: '',
      title: '',
      message: '',
      subscribeJobBoard: false,
      subscribeMailingList: false,
      genderEquityUpdates: false,
      boardLeadershipUpdates: false,
      sendToMailChimp: false,
      MailChimpResult: '',
      submitted: false,
    };
  }

  handleSubmit = async e => {
    e.preventDefault();
    const form = e.target;

    const result = await addToMailchimp(this.state.email, {
      FNAME: this.state.firstName,
      LNAME: this.state.lastName,
      'group[6172][17592186044416]': this.state.subscribeMailingList ? '17592186044416' : false,
      'group[6172][18014398509481984]': this.state.genderEquityUpdates ? '18014398509481984' : false,
      'group[6172][2048]': this.state.boardLeadershipUpdates ? '2048' : false,
    });
    this.setState({ MailChimpResult: result });

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() =>
        this.setState({
          statusMessage: 'Thank you for contacting us!',
        })
      )
      .catch(() =>
        this.setState({
          statusMessage: 'Something went wrong, please try again later.',
        })
      )
      .catch(error =>
        console.error('There was a problem with the form response', error)
      )
      .then(() =>
        console.log('%c Netlify Forms and Mailchimp data sent', 'color: green')
      )
      .finally(() =>
        this.setState({
          firstName: [],
          lastName: [],
          email: [],
          organization: [],
          message: [],
          title: [],
          submitted: true,
        })
      );
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleCheckboxChange = e => {
    switch(e.target.name) {
      case 'subscribeMailingList':
        this.setState({ subscribeMailingList: e.target.checked });
        break;
      case 'genderEquityUpdates':
        this.setState({ genderEquityUpdates: e.target.checked });
        break;
      case 'boardLeadershipUpdates':
        this.setState({ boardLeadershipUpdates: e.target.checked });
        break;
      default:
        break;
    }
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      organization,
      title,
      message,
      statusMessage,
      submitted
    } = this.state;

    const { contactPage } = this.props;

    return (
      <>
        <Form
          name="contact-leading-edge"
          method="post"
          action="/thanks/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
          className={submitted ? 'is-submitted' : false}
        >
          <input type="hidden" name="form-name" value="contact-leading-edge" />

          <div className="first-name">
            <Input
              name="firstName"
              id="firstName"
              value={firstName}
              required={true}
              onChange={this.handleChange}
            />
            <label htmlFor="firstName">First Name</label>
          </div>

          <div className="last-name">
            <Input
              name="lastName"
              id="lastName"
              required={true}
              value={lastName}
              onChange={this.handleChange}
            />
            <label htmlFor="lastName">Last Name</label>
          </div>

          <div className="email">
            <EmailInput
              name="email"
              id="email"
              required={true}
              value={email}
              onChange={this.handleChange}
            />
            <label htmlFor="email">Email</label>
          </div>

          <div className="organization">
            <Input
              name="organization"
              id="organization"
              required={false}
              value={organization}
              onChange={this.handleChange}
            />
            <label htmlFor="organization">Organization</label>
          </div>

          <div className="title">
            <Input
              name="title"
              id="title"
              required={false}
              value={title}
              onChange={this.handleChange}
            />
            <label htmlFor="title">Title</label>
          </div>

          <div className="message">
            <TextArea
              name="message"
              id="message"
              required={false}
              value={message}
              onChange={this.handleChange}
            />
            <label htmlFor="message">Message</label>
          </div>

          <SignMeUp>{contactPage.sign_me_up_text.text}</SignMeUp>

          <CheckBoxContainer>
            <label>
              <Checkbox
                checked={this.state.subscribeMailingList}
                name="subscribeMailingList"
                onChange={e => this.handleCheckboxChange(e)}
              />
            </label>

            <h6>{contactPage.newsletter_checkbox_title.text}</h6>

            <p>{contactPage.newsletter_checkbox_description.text}</p>
          </CheckBoxContainer>

          <CheckBoxContainer>
            <label>
              <Checkbox
                checked={this.state.boardLeadershipUpdates}
                name="boardLeadershipUpdates"
                onChange={e => this.handleCheckboxChange(e)}
              />
            </label>

            <h6>{contactPage.board_leadership_checkbox_title.text}</h6>

            <p>{contactPage.board_leadership_checkbox_description.text}</p>
          </CheckBoxContainer>

          {statusMessage && <StatusMessage>{statusMessage}</StatusMessage>}

          <FormButton type="submit">Join the Community</FormButton>
        </Form>
      </>
    );
  }
}