import React, { Component } from 'react';
import styled from '@emotion/styled'
import colors from "styles/colors";
/*import dimensions from "styles/dimensions";*/

const ButtonContainer = styled.button`
    position: relative;
    display: inline-block;
    padding: 1.4rem 3.33vw;
    background-color: #fff;
    color: ${colors.grey900};
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
    box-shadow: 0 0.4rem 1rem rgba(0, 0, 0, 0.1);
    outline: none;
    border: none;
    transition: background 0.08s ease-in-out, color 0.08s ease-in-out;
    
    &.Button--download {
        padding-left: 3.2rem;
        padding-right: 6.4rem;
        
        span {
            transition: color 0.08s ease-in-out;
        }
    
        svg {
            fill: ${colors.grey900};
            display: block;
            position: absolute;
            top: 50%;
            right: 2.4rem;
            transform: translateY(-50%);
            width: 2.4rem;
            height: 2.2rem;
            transition: fill 0.08s ease-in-out;
        }
    }
    
    &:hover {
        cursor: pointer;
        background-color: ${colors.yellow500};
        color: #fff;
        box-shadow: none;
        
        span {
            color: #fff;
        }
        
        svg {
            fill: #fff;
        }
    }
`

class Button extends Component {
    render() {
        const { children, ...props } = this.props;

        return (
            <ButtonContainer
                className={this.props.variant ? `Button--${this.props.variant}` : ''}
                onClick={this.props.onClick}
                {...props}>
                <span>{this.props.children}</span>
            </ButtonContainer>
        );
    }
}

export default Button;
